




















































































import Vue, { PropType } from "vue";
import { mapState, mapActions } from "pinia";
import { ContentItemType, ExternalLinkType } from "@/api/DoceoApi";

import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "CreateContentTypeSelect",
  components: { DoceoIcon },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    contentType: "article",
    externalSite: "Facebook",
    allowedExternalSites: [
      {
        name: "Facebook",
        value: "facebook",
      },
      {
        name: "Instagram",
        value: "instagram",
      },
      {
        name: "YouTube",
        value: "youtube"
      },
      {
        name: "LinkedIn",
        value: "linkedin"
      },
      {
        name: "Twitter",
        value: "twitter"
      }
    ],
    allowedContentTypes: [
      {
        name: "Article",
        value: "article",
      },
      {
        name: "External Link",
        value: "external",
      },
      {
        name: "Journal Link",
        value: "journal"
      }
    ],
    publishDate: "",
    datePickerOpen: false,
    DOCEO_ICONS,
  }),
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      let contentType
      if (this.contentType == "journal" || this.contentType == "external") {
        contentType = ContentItemType.DraftUserExternalLink
      } else {
        contentType = ContentItemType.DraftUserArticle
      }

      let externalType = null as ExternalLinkType | null

      if (this.contentType == "external") {
        switch (this.externalSite) {
          case "facebook":
            externalType = ExternalLinkType.Facebook;
            break;
          case 'instagram':
            externalType = ExternalLinkType.Instagram;
            break;
          case 'youtube':
            externalType = ExternalLinkType.YouTube;
            break;
          case 'linkedin':
            externalType = ExternalLinkType.LinkedIn;
            break;
          case 'twitter':
            externalType = ExternalLinkType.Twitter;
            break;
        }
      } else if (this.contentType == "journal") {
        externalType = ExternalLinkType.Journal;
      }

      this.$emit("submit", contentType, this.publishDate, externalType);
    },
  },
  computed: {},
});
