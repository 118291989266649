




































import Vue from "vue";
import { mapState, mapActions } from "pinia";
import { mapGetters } from "vuex";
import { ContentItemSummaryResult, ContentItemType, ExternalLinkType, UserContentClient } from "@/api/DoceoApi";

import ContentItemSummary from "@/components/contentItems/ContentItemSummary.vue";
import CreateContentTypeSelect from "@/components/contentItems/drafts/CreateContentTypeSelect.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";
import Roles from "@/constants/roles";

export default Vue.extend({
  name: "MyContent",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  components: {
    DoceoIcon,
    ContentItemSummary,
    CreateContentTypeSelect,
  },
  async created() {
    await this.loadItems();
  },
  data: () => ({
    dialogs: {
      typeSelect: {
        open: false,
        selectedContentType: null as ContentItemType | null,
      },
    },
    myContentItems: [] as ContentItemSummaryResult[],
    DOCEO_ICONS,
  }),
  methods: {
    async loadItems() {
      let userContentClient = new UserContentClient();
      let contentItems = await userContentClient.getUserContent();
      this.myContentItems = contentItems.filter((e) =>
        [
          // only show these content items
          ContentItemType.DraftUserArticle,
          ContentItemType.DraftUserExternalLink,
          ContentItemType.UserArticle,
          ContentItemType.UserExternalLink,
        ].includes(e.contentItemType)
      );
    },
    openTypeSelectDialog() {
      this.dialogs.typeSelect.open = true;
    },
    closeTypeSelectDialog() {
      this.dialogs.typeSelect.open = false;
    },
    submitTypeSelectDialog(type: ContentItemType, publishDate = "", externalType: string) {
      this.dialogs.typeSelect.open = false;
      this.dialogs.typeSelect.selectedContentType = type;

      let query: any;
      if (publishDate != "") {
        query = {
          publishDate,
          externalType: externalType,
        };
      } else {
        query = {
          externalType: externalType,
        };
      }

      switch (this.dialogs.typeSelect.selectedContentType) {
        case ContentItemType.DraftUserArticle:
          this.$router.push({
            name: "CreateContent",
            params: { contentItemType: "article" },
            query: query,
          });
          break;
        case ContentItemType.DraftUserExternalLink:
          this.$router.push({
            name: "CreateContent",
            params: { contentItemType: "externalLink" },
            query: query,
          });
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["roles"]),
    newestContentItems(): ContentItemSummaryResult[] {
      return [...this.myContentItems].sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime());
    },
    hasCreateAccess(): boolean {
      const allowedRoles = [Roles.Doctor, Roles.Admin, Roles.VoiceOf];
      return this.roles.some((role) => allowedRoles.includes(role));
    },
  },
  watch: {
    $route(to, from) {
      this.loadItems();
    },
  },
});
